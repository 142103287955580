import React from "react";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import OhtruTermsMain from "../components/OhtruTermsMain/OhtruTermsMain";

const OhtruTerms = () => {
  return (
    <Layout location="/">
      <SEO title="Ohtru terms" type="website" />
      <OhtruTermsMain />
    </Layout>
  );
};

export default OhtruTerms;
